@use "../../styles/color-palette";
@use "../../styles/typography";

.root {
  padding-top: 60px;
  padding-bottom: 60px;
}

.title {
  color: color-palette.get-color("primary-2");
}

.formSection {
  margin-top: 36px;
}

.sectionLabel {
  @include typography.a-btn;
  color: color-palette.get-color("secondary-1");
}

.fromRow {
  margin-top: 8px;
  margin-bottom: 8px;
}

.formCol {
  margin-top: 8px;
}

.submitButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 36px;
}
