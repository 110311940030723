@use "../../../styles/font";
@use "../../../styles/p-fin-calculator/variables" as p-fin-vars;

.root {
  display: flex;
  flex-direction: column;
}

.table {
  margin: 5px 12px;
}

.title {
  padding: 8px 12px;
  background-color: p-fin-vars.$primary-background-color;
  border-radius: 4px;
  color: p-fin-vars.$primary-color;
  font-size: 12px;
  font-weight: font.font-weight("medium");
}

.tableBody {
  padding: 5px 12px;
}
