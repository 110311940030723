@use "./typography";

html {
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @include typography.h1;
}

h2 {
  @include typography.h2;
}

h3 {
  @include typography.h3;
}

h4 {
  @include typography.h4;
}

.title {
  @include typography.title;
}

p {
  @include typography.p;
}

a {
  @include typography.a;
}

.a-btn {
  @include typography.a-btn;
}

.sub {
  @include typography.sub;
}

.subtitle {
  @include typography.subtitle;
}

#root {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
