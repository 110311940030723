@use "sass:map";
@use "../color-palette";
@use "../font";

@import "~bootstrap/scss/functions";

//
// Override Bootstrap-defined variables
//

$theme-colors: (
  primary: color-palette.get-color("primary-2"),
  secondary: color-palette.get-color("secondary-2"),
  danger: color-palette.get-color("danger"),
  light: white,
  dark: color-palette.get-color("primary-1"),
  bombay: color-palette.get-color("text"),
);

// --- Button ---

$btn-border-width: 0;

$btn-font-weight: font.font-weight("semiBold");

// TODO(nicolas 23Jun2022): Move sm & lg rounded button style to bootstrap.scss file
$btn-padding-y-sm: 8px;
$btn-padding-x-sm: 12px;
$btn-font-size-sm: 8px;
$btn-line-height-sm: 11px;
$btn-border-radius-sm: calc(27px / 2);

$btn-padding-y-lg: 16px;
$btn-padding-x-lg: 36px;
$btn-font-size-lg: 14px;
$btn-line-height-lg: 18px;
$btn-font-weight-lg: font.font-weight("extraBold");
$btn-border-radius-lg: calc(50px / 2);

// --- Input ---

$input-padding-y: 16px;
$input-padding-x: 25px;
$input-bg: color-palette.get-color("background-2");
$input-color: color-palette.get-color("secondary-1");
$input-font-weight: font.font-weight("bold");
$input-border-width: 0;
$input-border-radius: calc(56px / 2);
$input-placeholder-color: color-palette.get-color("text");

// --- Form ---

$form-label-color: color-palette.get-color("text");

// --- Tooltip ---

$tooltip-bg: #494949;
$tooltip-opacity: 1;

//
// Import original Bootstrap-defined variables
//

@import "~bootstrap/scss/variables";
