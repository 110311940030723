@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  padding: 12px 22px;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
  border-left: 10px solid #71CAC5;
  border-radius: 4px;

  &.onGoing {
    border-left-color: #FFB95C;
  }
}

.title {
  color: #666666;
  font-size: 15px;
  font-weight: font.font-weight("semiBold");
}

.time {
  color: color-palette.get-color("secondary-1");
  font-size: 10px;
  font-weight: font.font-weight("regular");
}

.location {
  margin-top: 8px;
  color: color-palette.get-color("text");
  font-size: 12px;
  font-weight: font.font-weight("regular");
  white-space: pre-wrap;
}

.remarks {
  margin-top: 8px;
  color: color-palette.get-color("text");
  font-size: 12px;
  font-weight: font.font-weight("regular");
  white-space: pre-wrap;
}
