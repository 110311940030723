@use "../../../styles/color-palette";
@use "../../../styles/typography";
@use "../../../styles/font";
@use "../variables" as vars;
@use "../mixins";

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 52px;
  padding: 0 24px;
  color: color-palette.get-color("text");
  transition: color, background-color, min-height 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  @include mixins.when-menu-link-is-active {
    color: white;
    background-color: color-palette.get-color("primary-2");
  }

  @include mixins.when-sidebar-collapsed {
    flex-direction: column;
    justify-content: center;
    min-height: vars.$item-collapsed-height;
  }
}

.iconWrapper {
  display: none;

  @include mixins.when-sidebar-collapsed {
    display: block;
  }

  svg {
    width: vars.$item-icon-collapsed-size;
    height: vars.$item-icon-collapsed-size;
  }
}

.label {
  @include typography.h3;
  transition: margin, font-size, font-weight 0.3s;

  @include mixins.when-sidebar-collapsed {
    margin-top: 4px;
    font-size: vars.$item-label-collapsed-font-size;
    font-weight: font.font-weight("regular");
  }
}
