@use "../../styles/typography";
@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  text-decoration: inherit;

  &.active {
    background-color: color-palette.get-color("primary-2");
  }
}

.statusCol {
  flex: 0 0 auto;
  padding: 0 12px;

  .statusEmpty {
    display: inline-block;
    width: 16px;
  }
  .statusPlay {
    color: color-palette.get-color("background-2");
  }
  .statusLock {
    color: color-palette.get-color("text");
    
    &.active {
      color: color-palette.get-color("background-2");
    }
  }
}

.thumbnailCol {
  flex: 0 0 auto;

  .thumbnailImage {
    width: 66px;
  }
}

.detailsCol {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 12px;

  .title {
    @include typography.title;
    color: color-palette.get-color("secondary-1");

    &.active {
      color: color-palette.get-color("background-2");
    }
  }

  .duration {
    color: color-palette.get-color("text");

    &.active {
      color: color-palette.get-color("background-2");
    }
  }
  .durationValue {
    @include typography.sub;
  }
}
