@use "../../styles/react-pro-sidebar";

.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.backgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;

  &.sticky {
    position: fixed;
    left: react-pro-sidebar.$sidebar-collapsed-width;
    transition: left react-pro-sidebar.$sidebar-transition-duration;

    &.offset {
      left: react-pro-sidebar.$sidebar-width;
    }
  }
}
