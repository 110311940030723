@use "../../styles/bootstrap/utils" as bs-utils;

.root {
  padding: 48px 0 24px 0;
  background-color: white;

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    padding: 8px 0;
  }
}
