@use "../../../styles/font";

@mixin field-input-value {
  color: #95989d;
  font-size: 12px;
  font-weight: font.font-weight("regular");
  line-height: 14px;
}

@mixin field-input {
  height: 32px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  @include field-input-value;
}
