@use "../../styles/container";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.sortingRow {
  margin-bottom: 16px;
}

.dataTableTable {
  table-layout: fixed;
}

.dataTablePinnedIndicator {
  $width: 4px;
  $offset: -10px;

  display: inline-block;
  margin-left: $offset - $width;
  margin-right: -$offset;
  width: $width;
  height: $width;
  border-radius: $width / 2;

  &.pinned {
    background-color: #ffb95c;
  }
}

.dataTableTitleCell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
