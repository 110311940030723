@use "../../../styles/color-palette";
@use "../variables" as vars;
@use "../mixins";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: vars.$item-collapsed-height;
  padding: 0 24px;
  color: color-palette.get-color("text");
  transition: color, background-color, padding 0.3s;

  &:hover {
    opacity: 0.7;
  }

  @include mixins.when-sidebar-collapsed {
    flex-direction: column;
    justify-content: center;
    padding: 0 8px;
  }
}

.iconWrapper {
  svg {
    width: 24px;
    height: 24px;
  }
}

.imageWrapper {
  margin-left: 16px;
  font-size: vars.$item-label-font-size;
  transition: margin, font-size 0.3s;

  @include mixins.when-sidebar-collapsed {
    display: none;
  }

  .image {
    width: 149px;
  }
}
