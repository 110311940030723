@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/container";
@use "../../styles/font";
@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

$num-of-items: 5;
$item-inner-width: 70px;
$item-inner-height: 32px;
$point-size: 8px;

.root {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-left: calc(-1 * (100% / $num-of-items - $item-inner-width) / 2);
  margin-right: calc(-1 * (100% / $num-of-items - $item-inner-width) / 2);

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    margin-left: 0;
    margin-right: 0;
  }
}

.termItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 8px;

  &::before {
    content: "";
    width: $point-size;
    height: $point-size;
    margin-bottom: 8px;
    border-radius: 50%;
    background-color: p-fin-vars.$primary-background-color;
  }

  &::after {
    content: "";
    position: absolute;
    top: calc(($point-size - 1px) / 2);
    left: 0;
    right: 0;
    height: 1px;
    background-color: p-fin-vars.$primary-background-color;
  }
  &:first-child {
    &::after {
      left: 50%;
    }
  }
  &:last-child {
    &::after {
      right: 50%;
    }
  }
}

.termLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $item-inner-width;
  height: $item-inner-height;
  background-color: p-fin-vars.$primary-background-color;
  border-radius: 4px;
  font-size: 12px;
  font-weight: font.font-weight("regular");
  color: p-fin-vars.$primary-color;
}

.termSelect {
  flex: none;
  width: $item-inner-width;
  height: $item-inner-height;
}
