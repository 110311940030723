@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/typography";
@use "../../styles/container";

@mixin mobile-layout {
  @include bs-utils.media-breakpoint-down(md, bs-utils.$grid-breakpoints) {
    @content;
  }
}

.root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  @include mobile-layout {
    flex-direction: column;
  }
}

.bannerSection {
  flex: 0 0 auto;
  width: 300px;

  @include mobile-layout {
    width: 100%;
    order: 2;
  }
}

.contentSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .spacer {
    flex: 1;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    @include container.horizontal-padding;
  }

  .title {
    margin-bottom: 32px;
  }

  .content {
    width: 100%;
    max-width: 620px;
    margin: auto;
  }

  .loginForm {
    display: grid;
    gap: 12px;
  }

  .orSeparator {
    display: flex;
    align-items: center;
    margin: 8px 0;

    &::before, &::after {
      content: "";
      width: 100%;
      border-top: 1px solid color-palette.get-color("border");
      margin: 0 8px;
    }

    .label {
      color: color-palette.get-color("text");
    }
  }

  .copyright {
    @include typography.a;
    color: #A6AEB8;
    margin: 24px;
  }
}
