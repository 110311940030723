@use "../../../styles/color-palette";
@use "../variables" as vars;
@use "../mixins";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: vars.$item-height;
  padding: 0 24px;
  color: color-palette.get-color("text");
  transition: color, background-color, min-height, padding 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  @include mixins.when-menu-link-is-active {
    color: white;
    background-color: color-palette.get-color("primary-2");
  }

  @include mixins.when-sidebar-collapsed {
    flex-direction: column;
    justify-content: center;
    min-height: vars.$item-collapsed-height;
    padding: 0 8px;
  }
}

.iconWrapper {
  svg {
    width: vars.$item-icon-size;
    height: vars.$item-icon-size;
    transition: width, height 0.3s;

    @include mixins.when-sidebar-collapsed {
      width: vars.$item-icon-collapsed-size;
      height: vars.$item-icon-collapsed-size;
    }
  }
}

.label {
  margin-left: 16px;
  font-size: vars.$item-label-font-size;
  transition: margin, font-size 0.3s;

  @include mixins.when-sidebar-collapsed {
    margin-left: 0;
    margin-top: 4px;
    font-size: vars.$item-label-collapsed-font-size;
    text-align: center;
  }
}
