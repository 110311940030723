@use "../../styles/color-palette";
@use "../../styles/container";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.logoContainer {
  $logo-width: 254px;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 36px;

  .logo {
    width: 100%;
    max-width: $logo-width;
  }

  .sloganContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: $logo-width;
    margin-top: 8px;
    padding: 0 16px;

    .slogan {
      color: color-palette.get-color("primary-3");
      font-size: 20px;
      font-weight: font.font-weight("regular");
    }
  }
}

.purposeCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 28px 22px;
  background-color: #ecf6ff;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 color-palette.get-color("shadow");

  .cardIcon {
    width: 60px;
    height: 60px;
  }
  .cardDetails {
    margin-left: 16px;
  }
  .cardTitle {
    margin-bottom: 8px;
    color: color-palette.get-color("primary-3");
    font-size: 24px;
    font-weight: font.font-weight("bold");
  }
  .cardDescription {
    color: color-palette.get-color("secondary-1");
    font-size: 14px;
    font-weight: font.font-weight("regular");
    white-space: pre-wrap;
  }
}
