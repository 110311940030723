@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  padding: 24px 36px;
  border-radius: 4px;
  background-color: white;
}

.title {
  color: color-palette.get-color("secondary-1");
  font-weight: font.font-weight("bold");
  white-space: pre-wrap;
}
