@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/typography";

.root {
  text-decoration: inherit;
}

.coverImageContainer {
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.coverImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  margin-bottom: 0;
  color: color-palette.get-color("primary-2");
  font-weight: font.font-weight("semiBold");
  text-decoration: underline;
}

.photosCount {
  @include typography.sub;
  color: color-palette.get-color("text");
}
