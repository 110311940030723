@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/typography";

.root {
  display: flex;
  flex-direction: row;
}

.date {
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: font.font-weight("bold");
  color: color-palette.get-color("secondary-1");
}

.vr {
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 16px;
  width: 2px;
  background-color: #B1D7E2;
  opacity: 1;
}

.details {
  flex: 1;
}

.host {
  @include typography.sub;
  color: color-palette.get-color("text");
}

.title {
  font-size: 12px;
  font-weight: font.font-weight("semiBold");
  color: color-palette.get-color("secondary-1");
}
