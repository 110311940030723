@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  border: 1px solid color-palette.get-color("border");
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
}

.table {
  display: table;
  width: 100%;
}

.tableHeaderGroup {
  display: table-header-group;

  .tableCell {
    border-bottom: 1px solid color-palette.get-color("border");
  }
}

.tableRowSpacer {
  height: 12px;
}

.tableRowGroup {
  display: table-row-group;
}

.tableRow {
  display: table-row;

  &.link {
    text-decoration: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

.tableCell {
  display: table-cell;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: font.font-weight("regular");
  color: color-palette.get-color("secondary-1");

  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
}

.noDataRow {
  padding: 32px;
  text-align: center;

  .noDataText {
    color: color-palette.get-color("text");
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 16px;
}

.hideOnSm {
  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    display: none;
  }
}
.hideOnMd {
  @include bs-utils.media-breakpoint-down(md, bs-utils.$grid-breakpoints) {
    display: none;
  }
}
.hideOnLg {
  @include bs-utils.media-breakpoint-down(lg, bs-utils.$grid-breakpoints) {
    display: none;
  }
}
.hideOnXl {
  @include bs-utils.media-breakpoint-down(xl, bs-utils.$grid-breakpoints) {
    display: none;
  }
}
.hideOnXxl {
  @include bs-utils.media-breakpoint-down(xxl, bs-utils.$grid-breakpoints) {
    display: none;
  }
}