@use "../../styles/color-palette";

.root {
  position: relative;
}

.tileHighlighted {
  &:not(:global(.react-calendar__tile--now)) {
    :global(abbr) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      margin: auto;
      border-radius: 50%;
      color: white;
      background-color: color-palette.get-color("secondary-2");
    }
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(213 212 212 / 73%);
}
