@use "./font";

@mixin h1 {
  font-size: 40px;
  font-weight: font.font-weight("semiBold");
}

@mixin h2 {
  font-size: 24px;
  font-weight: font.font-weight("extraBold");
}

@mixin h3 {
  font-size: 20px;
  font-weight: font.font-weight("extraBold");
}

@mixin h4 {
  font-size: 16px;
  font-weight: font.font-weight("extraBold");
}

@mixin title {
  font-size: 13px;
  font-weight: font.font-weight("bold");
}

@mixin p {
  font-size: 15px;
  font-weight: font.font-weight("bold");
}

@mixin a {
  font-size: 15px;
  font-weight: font.font-weight("regular");
}

@mixin a-btn {
  font-size: 14px;
  font-weight: font.font-weight("extraBold");
  color: #5e75a8;
}

@mixin sub {
  font-size: 12px;
  font-weight: font.font-weight("regular");
}

@mixin subtitle {
  font-size: 12px;
  font-weight: font.font-weight("regular");
  text-decoration: underline;
}
