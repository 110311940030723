@use "./bootstrap/utils" as bs-utils;

$paddings: (
  xs: 16px,
  sm: 32px,
  md: 64px,
);

@mixin horizontal-padding-left {
  @each $breakpoint, $padding in $paddings {
    @include bs-utils.media-breakpoint-up($breakpoint, bs-utils.$grid-breakpoints) {
      padding-left: $padding;
    }
  }
}

@mixin horizontal-padding-right {
  @each $breakpoint, $padding in $paddings {
    @include bs-utils.media-breakpoint-up($breakpoint, bs-utils.$grid-breakpoints) {
      padding-right: $padding;
    }
  }
}

@mixin horizontal-padding {
  @include horizontal-padding-left;
  @include horizontal-padding-right;
}

@mixin vertical-padding-top {
  padding-top: 50px;
}

@mixin vertical-padding-bottom {
  padding-bottom: 50px;
}

@mixin vertical-padding {
  @include vertical-padding-top;
  @include vertical-padding-bottom;
}

@mixin max-width {
  width: 100%;
  max-width: map-get(bs-utils.$container-max-widths, xl);
  margin-right: auto;
  margin-left: auto;
}
