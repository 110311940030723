@use "../../styles/container";
@use "../../styles/color-palette";
@use "../../styles/typography";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.categoryDescription {
  @include typography.a;
  color: color-palette.get-color("secondary-1");
}
