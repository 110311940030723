@use "../../styles/color-palette";
@use "../../styles/typography";
@use "../../styles/font";

.root {
  text-decoration: inherit;
}

.card {
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 color-palette.get-color("shadow");
  overflow: hidden;
}

.title {
  margin-bottom: 0;
  color: color-palette.get-color("secondary-3");
  font-weight: font.font-weight("semiBold");
}

.dateRange {
  @include typography.sub;
  color: color-palette.get-color("text");
}

.description {
  margin-top: 6px;
  @include typography.sub;
  color: color-palette.get-color("secondary-1");
}

.actionButton {
  margin-top: 16px;
}
