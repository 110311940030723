@use "./bootstrap/utils" as bs-utils;
@use "./color-palette";
@use "./font";

@import "~react-calendar/dist/Calendar.css";

$disabled-color: #f0f0f0;
$enabled-focus-color: #e6e6e6;
$now-color: color-palette.get-color("primary-2");
$now-enabled-focus-color: mix(color-palette.get-color("primary-2"), white, 20);
$has-active-color: color-palette.get-color("primary-2");
$has-active-focus-color: mix(color-palette.get-color("primary-2"), white, 80);
$active-color: color-palette.get-color("primary-2");
$active-focus-color: color-palette.get-color("primary-2");

.react-calendar {
  width: auto;
  border: none;
  font-size: 14px;
  font-family: inherit;

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    font-size: 12px;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: inherit;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #919191;
}

.react-calendar__navigation {
  padding: 0 40px;

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    padding: 0 16px;
  }

  &__label__labelText {
    font-weight: font.font-weight("extraBold");
  }
}

// Hide year navigation buttons
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

// Override tile styles for century, decade & year views
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year,
.react-calendar__year-view__months__month {
  &.react-calendar__tile {
    padding: 1em 0;

    &:disabled {
      background-color: $disabled-color;
    }
    &:enabled:hover,
    &:enabled:focus {
      background-color: $enabled-focus-color;
    }

    &--now {
      background: inherit;
      border: 1px solid $now-color;
      color: inherit;
    }
    &--now:enabled:hover,
    &--now:enabled:focus {
      background: $now-enabled-focus-color;
    }

    &--hasActive {
      background: $has-active-color;
      color: white;
    }
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      background: $has-active-focus-color;
    }

    &--active {
      color: white;
      background: $active-color;
    }
    &--active:enabled:hover,
    &--active:enabled:focus {
      background: $active-focus-color;
    }
  }
}

.react-calendar__month-view__weekdays {
  font-size: inherit;

  &__weekday {
    font-size: inherit;
    font-weight: font.font-weight("bold");
  
    abbr {
      cursor: auto;
      text-decoration: none;
    }
  }
}

// Override tile styles for month view
.react-calendar__month-view__days__day {
  &.react-calendar__tile {
    padding: 0;

    abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      margin: auto;
      border-radius: 50%;
    }

    &:disabled {
      background-color: inherit;
      abbr {
        background-color: $disabled-color;
      }
    }
    &:enabled:hover,
    &:enabled:focus {
      background-color: inherit;
      abbr {
        background-color: $enabled-focus-color;
      }
    }

    &--now {
      background: inherit;
      abbr {
        border: 1px solid $now-color;
      }
    }
    &--now:enabled:hover,
    &--now:enabled:focus {
      background: inherit;
      abbr {
        background: $now-enabled-focus-color;
      }
    }

    &--hasActive {
      background: inherit;
      abbr {
        background: $has-active-color;
      }
    }
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      background: inherit;
      abbr {
        background: $has-active-focus-color;
      }
    }

    &--active {
      color: white;
      background: inherit;
      abbr {
        background: $active-color;
      }
    }
    &--active:enabled:hover,
    &--active:enabled:focus {
      background: inherit;
      abbr {
        background: $active-focus-color;
      }
    }
  }
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: inherit;
  abbr {
    background-color: #e6e6e6;
  }
}
