@use "../../styles/container";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.sortingRow {
  margin-bottom: 16px;
}
