@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  --bs-breadcrumb-divider: ">";

  :global(.breadcrumb) {
    align-items: center;
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: center;
  padding: 4px;

  &.withLink {
    margin: -4px;
  }

  &:not(:last-child):after {
    content: ">";
    padding: 4px;
    color: color-palette.get-color("secondary-1");
  }
}

.link {
  padding: 4px;
}

.label {
  color: color-palette.get-color("secondary-1");
  font-size: 16px;
  font-weight: font.font-weight("regular");
  line-height: 1.5;
}
