@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/typography";
@use "../../styles/container";

$container-padding-top: 60px;
$container-padding-bottom: 40px;

@mixin mobile-layout {
  @include bs-utils.media-breakpoint-down(md, bs-utils.$grid-breakpoints) {
    @content;
  }
}

@mixin desktop-layout {
  @include bs-utils.media-breakpoint-up(md, bs-utils.$grid-breakpoints) {
    @content;
  }
}

.root {
  height: 100%;
  min-height: 100vh;
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  height: 100%;

  @include mobile-layout {
    flex-direction: column;
  }
}

.lessonSection {
  flex: 1;
  padding-top: $container-padding-top;
  @include container.horizontal-padding-left;
  @include container.horizontal-padding-right;

  @include desktop-layout {
    padding-bottom: $container-padding-bottom;
  }

  .title {
    margin-bottom: 0;
    color: color-palette.get-color("primary-1");
  }

  .videoContainer {
    position: relative;
    margin-bottom: 12px;
  }
  .video {
    display: block;
    width: 100%;
    max-height: 500px;
  }
  .permissionDeniedOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 48px;
    background-color: rgba(color-palette.get-color("primary-1"), 0.7);

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: rgba(color-palette.get-color("background-1"), 0.2);
    }
    .icon {
      font-size: 32px;
      color: color-palette.get-color("background-1");
    }

    .message {
      margin-top: 16px;
      @include typography.a-btn;
      color: color-palette.get-color("background-1");
      text-align: center;
      white-space: per-line;
    }
  }

  .duration {
    color: color-palette.get-color("text");
  }
  .durationValue {
    @include typography.a;
  }

  .freeLessonLabel {
    margin-left: 36px;
  }

  .detailRow {
    margin-top: 24px;
  }

  .detailTitle {
    margin-bottom: 0;
    color: color-palette.get-color("secondary-3");
  }
  
  .detailContent {
    margin-bottom: 0;
    color: color-palette.get-color("secondary-1");
    @include typography.a;
  }
}

.playlistSection {
  flex: 0 0 auto;
  padding-top: $container-padding-top;
  padding-bottom: $container-padding-bottom;

  @include desktop-layout {
    // box-shadow: 0 4px 4px 0 rgba(#C4C4C4, 0.4);
    box-shadow: -2px 0 2px 0 rgba(#C4C4C4, 0.2);
    width: 260px;
  }

  .quizHeader {
    padding-bottom: 24px;
    padding-left: 20px;
    @include container.horizontal-padding-right;
    border-bottom: 1px solid rgba(color-palette.get-color("text"), 0.2);

    @include mobile-layout {
      @include container.horizontal-padding-left;
    }
  }

  .header {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    @include container.horizontal-padding-right;

    @include mobile-layout {
      @include container.horizontal-padding-left;
    }

    .title {
      color: color-palette.get-color("primary-1");
    }

    .categoryDetailsRow {
      display: flex;
      align-items: center;
    }

    .lessonCount {
      @include typography.p;
      color: color-palette.get-color("secondary-1");
    }

    .duration {
      margin-left: 36px;
      color: color-palette.get-color("secondary-1");
    }
    .durationValue {
      @include typography.sub;
    }
  }
}

.notFoundContainer {
  padding-top: $container-padding-top;
  padding-bottom: $container-padding-bottom;
  @include container.horizontal-padding;
}
