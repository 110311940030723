@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/container";
@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.instructionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .instruction {
    color: color-palette.get-color("text");
    font-size: 12px;
    font-weight: font.font-weight("regular");
  }

  .viewInterestRateHistoryButton {
    font-size: 12px;
  }
}

.fieldsGroup {
  padding: 16px;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  background-color: white;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: p-fin-vars.$primary-color;
    font-size: 14px;
    font-weight: font.font-weight("regular");

    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 24px;
      margin-right: 12px;
      background-color: p-fin-vars.$primary-color;
    }
  }

  .fieldLabel {
    margin-bottom: 6px;
    color: p-fin-vars.$label-color;
    font-size: 12px;
    font-weight: font.font-weight("medium");
  }

  .termContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
      gap: 12px;
    }

    .termControl {
      overflow-x: auto;
      flex: 1;
      width: 0;
    }
  }
}

.loanDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  padding: 8px 16px;
  background-color: p-fin-vars.$primary-background-color;
  border-radius: 4px;

  .loanDetailItem {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    padding: 0 4px;

    .label {
      flex: none;
      margin-right: 12px;
      color: p-fin-vars.$primary-color;
      font-size: 14px;
      font-weight: font.font-weight("medium");
    }
    .value {
      flex: 1 0 auto;
      color: p-fin-vars.$label-color;
      font-size: 14px;
      font-weight: font.font-weight("medium");
    }
  }
}

.resetBtn {
  font-size: 12px;
}
