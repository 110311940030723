@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/container";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.calendarSection {
  display: flex;
  flex-direction: row;
  background-color: white;
  border: 1px solid color-palette.get-color("border");
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    flex-direction: column;
  }

  .calendar {
    flex: 55%;
    padding: 24px 8px 8px 8px;
    background: none;
    border-right: 1px solid color-palette.get-color("border");

    @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
      border-right: none;
      padding-bottom: 24px;
      border-bottom: 1px solid color-palette.get-color("border");
    }
  }
  .calendarEventsContainer {
    flex: 45%;
    padding: 36px 16px;
  }
  .dateLabel {
    font-weight: font.font-weight("semiBold");
    margin-bottom: 16px;
  }
  .eventCardsContainer {
    $card-gap: 8px;

    overflow-y: auto;
    margin: -$card-gap 0;
    padding-right: 16px;
    max-height: 300px;

    @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
      padding-right: 0;
      max-height: none;
    }

    .eventCard {
      margin: $card-gap 0;
    }
    .noResult {
      text-align: center;
      padding: 32px 16px;
      color: color-palette.get-color("text");
    }
  }
}

.upcomingEventSection {
  margin-top: 20px;

  .title {
    color: color-palette.get-color("text");
    font-weight: font.font-weight("semiBold");
  }

  .eventCard {
    height: 100%;
  }
  .noItemsMessage {
    text-align: center;
    padding: 32px 16px;
    color: color-palette.get-color("text");
  }
}
