@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

.root {
  padding: 4px 8px;
  border-radius: 4px;
  color: white;

  &.positive {
    background-color: p-fin-vars.$positive-background-color;
  }
  &.zero {
    background-color: p-fin-vars.$zero-background-color;
  }
  &.negative {
    background-color: p-fin-vars.$negative-background-color;
  }
}
