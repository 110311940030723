@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/container";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.sectionTitle {
  font-weight: font.font-weight("semiBold");
}

.chartSection {
  margin-bottom: 40px;

  .chart {
    padding: 8px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
  }

  .noDataMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    text-align: center;
    padding: 16px;
    color: color-palette.get-color("text");
  }
}

.billboardItemsContainer {
  $item-gap: 8px;

  margin-top: 16px;

  .billboardHeader {
    display: flex;
    flex-direction: row;
    padding: 0 16px;

    @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
      padding: 0 8px;
    }
  }
  .headerItem {
    padding: 0 16px;
    color: color-palette.get-color("secondary-1");
    font-size: 14px;
    font-weight: font.font-weight("regular");

    @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
      padding: 0 8px;
    }

    &.ranking {
      flex: none;
    }
    &.spacer {
      flex: 1;
    }
    &.submittedFYC {
      width: 20%;
    }
    &.submittedCases {
      width: 20%;
    }
  }

  .billboardItem {
    margin: $item-gap 0;
  }

  .noItemsMessage {
    text-align: center;
    padding: 32px 16px;
    color: color-palette.get-color("text");
  }
}
