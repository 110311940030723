@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  background-color: color-palette.get-color("background-2");
  min-height: 100vh;
}

.toolbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .quizTitle {
    color: white;
    font-size: 16px;
    font-weight: font.font-weight("regular");
    text-decoration: underline;
  }
}

.mainContent {
  padding-top: 24px;
  padding-bottom: 48px;
}
