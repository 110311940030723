@use "../../styles/color-palette";
@use "../../styles/typography";

.root {
  text-decoration: inherit;
}

.card {
  box-shadow: 0 4px 10px 0 color-palette.get-color("shadow");
  overflow: hidden;
}

.title {
  @include typography.p;
  color: color-palette.get-color("secondary-3");
}

.text {
  @include typography.sub;
  color: color-palette.get-color("text");
}
