@use "./color-palette";

$sidebar-bg-color: white;
$sidebar-color: color-palette.get-color("secondary-1");
$sidebar-width: 270px;
$sidebar-collapsed-width: 72px;
$submenu-bg-color: white;
$submenu-bg-color-collapsed: white;
$icon-size: 36px;

$sidebar-transition-duration: 0.3s;

@import "~react-pro-sidebar/dist/scss/variables.scss";
@import "~react-pro-sidebar/dist/scss/partials/animation";
@import "~react-pro-sidebar/dist/scss/partials/sidebar";
// @import "~react-pro-sidebar/dist/scss/partials/menu";
@import "~react-pro-sidebar/dist/scss/partials/popperArrow";
@import "~react-pro-sidebar/dist/scss/partials/reactSlidedown";
