@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    padding: 16px 8px;
  }
}

.cell {
  padding: 0 16px;
  color: color-palette.get-color("secondary-1");
  font-size: 14px;
  font-weight: font.font-weight("regular");

  &.ranking {
    flex: none;
  }
  &.name {
    flex: 1;
    font-size: 16px;
    font-weight: font.font-weight("semiBold");
  }
  &.submittedFYC {
    width: 20%;
    word-break: break-word;
  }
  &.submittedCases {
    width: 20%;
    word-break: break-word;
  }

  @include bs-utils.media-breakpoint-down(sm, bs-utils.$grid-breakpoints) {
    padding: 0 8px;
  }
}
