@use "../../styles/font";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 170px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.contentContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.title {
  color: white;
  font-weight: font.font-weight("extraBold");
}
