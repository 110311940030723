@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/typography";
@use "../../styles/container";

$container-padding-top: 36px;
$container-padding-bottom: 48px;

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentRow {
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  @include bs-utils.media-breakpoint-down(md, bs-utils.$grid-breakpoints) {
    flex-direction: column;
  }
}

.primaryCol {
  flex: 1;
  padding-top: $container-padding-top;
  padding-bottom: $container-padding-bottom;
  @include container.horizontal-padding-left;
  @include container.horizontal-padding-right;
}

.secondaryCol {
  flex: 0 0 auto;
  width: 240px;
  padding: $container-padding-top 24px $container-padding-bottom 24px;
  border-left: 1px solid color-palette.get-color("background-2");
  background-color: white;

  @include bs-utils.media-breakpoint-down(md, bs-utils.$grid-breakpoints) {
    width: auto;
  }
}

.newsSection {
  .title {
    color: color-palette.get-color("secondary-1");
  }
  .list {
    margin-top: 16px;
    margin-bottom: 48px;
  }
  .item {
    margin: 8px 0;
  }
  .noItemsMessage {
    padding: 12px;
    color: color-palette.get-color("text");
    text-align: center;
  }
}

.albumsSection {
  $item-padding: 4px;

  .title {
    color: color-palette.get-color("primary-2");
  }
}

.eventsSection {
  .title {
    color: color-palette.get-color("secondary-1");
  }

  .dailyEventsList {
    margin-top: 32px;
  }
  .dailyEventsItem {
    margin: 16px 0;
  }
  .noItemsMessage {
    padding: 12px;
    color: color-palette.get-color("text");
    text-align: center;
  }

  .dayLabel {
    @include typography.sub;
    color: color-palette.get-color("secondary-3");
  }

  .eventItem {
    margin: 8px 0;
  }
}
