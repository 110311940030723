@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/typography";

.root {
  padding: 20px 16px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
}

.title {
  color: color-palette.get-color("secondary-3");
  font-weight: font.font-weight("semiBold");
}

.detailRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  color: color-palette.get-color("secondary-1");
  @include typography.sub;

  .icon {
    flex: none;
  }

  .label {
    flex: 1;
    margin-left: 8px;
  }
}

.remarks {
  margin-top: 8px;
  @include typography.sub;
  color: color-palette.get-color("secondary-1");
}
