@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/typography";

.root {
  background-color: color-palette.get-color("background-2");
  min-height: 100vh;
}

.toolbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .albumTitle {
    color: white;
    font-size: 16px;
    font-weight: font.font-weight("regular");
    text-decoration: underline;
  }
}

.mainContent {
  padding-top: 24px;
  padding-bottom: 48px;

  .photosContainer {
    padding: 16px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
  }

  .albumDescription {
    color: color-palette.get-color("secondary-1");
    @include typography.a;
  }

  .loadMoreButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .loadMoreBtn {
    margin-top: 64px;
  }

  .noItemsMessage {
    text-align: center;
    padding: 32px 16px;
    color: color-palette.get-color("text");
  }
}
