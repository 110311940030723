@use "../../styles/color-palette";
@use "./mixins";

.proSidebar {
  box-shadow: 0 4px 20px color-palette.get-color("shadow");
}

.header {
  border-bottom: none !important;
}

.content {
  border-bottom: none !important;
  margin-top: 24px;
  margin-bottom: 24px;

  @include mixins.when-sidebar-collapsed {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.footer {
  border-top: none !important;
}

.userMenuItem {
  margin-bottom: 88px;

  @include mixins.when-sidebar-collapsed {
    margin-bottom: 0;
  }
}

.homeMenuItem {
  margin-bottom: 24px;

  @include mixins.when-sidebar-collapsed {
    margin-bottom: 0;
  }
}

.hrMenuItem {
  display: none;
  margin: 8px 16px;

  @include mixins.when-sidebar-collapsed {
    display: block;
  }
}
