@use "../../styles/typography";
@use "../../styles/color-palette";

.root {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 color-palette.get-color("shadow");
  text-decoration: inherit;
}

.statusIndicator {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  
  &.pinned {
    background-color: #FFB95C;
  }
}

.date {
  flex: 0 0 auto;
  width: 55px;
  margin-left: 12px;
  margin-right: 8px;
  @include typography.sub;
  color: color-palette.get-color("text");
}

.title {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include typography.subtitle;
  color: color-palette.get-color("secondary-1");
}
