@use "../../styles/color-palette";
@use "../../styles/container";
@use "../../styles/font";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex: 0 0 auto;
}

.contentContainer {
  @include container.vertical-padding;
}

.card {
  border: 1px solid color-palette.get-color("border");
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
  padding: 20px 24px;

  .metaRow {
    color: color-palette.get-color("secondary-1");
    font-size: 14px;
    font-weight: font.font-weight("regular");
    margin-bottom: 4px;
  }
  .cardContent {
    color: color-palette.get-color("secondary-1");
    font-size: 14px;
    font-weight: font.font-weight("regular");
    white-space: pre-wrap;

    :global(p) {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: inherit;
    }
  }
}
