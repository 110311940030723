@use "../../styles/color-palette";
@use "../../styles/font";

.root {
  background-color: color-palette.get-color("background-2");
  min-height: 100vh;
}

.toolbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .quizTitle {
    color: white;
    font-size: 16px;
    font-weight: font.font-weight("regular");
    text-decoration: underline;
  }
}

.mainContent {
  padding-top: 24px;
  padding-bottom: 48px;
}

.resultCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  min-height: 450px;
  padding: 32px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px 0 color-palette.get-color("shadow");
  overflow: hidden;

  .topLeftBackground {
    z-index: -1;
  }
  .bottomRightBackground {
    z-index: -2;
  }

  .scoreLabel {
    color: color-palette.get-color("secondary-1");
    font-weight: font.font-weight("bold");
  }

  .scoreValue {
    margin-top: 16px;
    color: color-palette.get-color("secondary-3");
    font-weight: font.font-weight("semiBold");
  }

  .scoreDescription {
    margin-top: 48px;
    color: color-palette.get-color("secondary-1");
    font-size: 16px;
    font-weight: font.font-weight("regular");
    text-align: center;
  }
}
