@use "../../styles/color-palette";
@use "../../styles/typography";
@use "../../styles/font";

.root {
  text-decoration: inherit;
}

.card {
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 color-palette.get-color("shadow");
  overflow: hidden;
}

.requireLoginMessage {
  @include typography.sub;
  display: flex;
  align-items: center;
  color: color-palette.get-color("text");
}

.requireLoginMessageText {
  margin-left: 4px;
}

.title {
  margin-bottom: 0;
  color: color-palette.get-color("secondary-3");
  font-weight: font.font-weight("semiBold");
}

.duration {
  color: color-palette.get-color("text");
}

.durationValue {
  @include typography.sub;
}

.watchButton {
  margin-top: 16px;
}
