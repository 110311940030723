@use "../../../styles/font";
@use "../../../styles/p-fin-calculator/variables" as p-fin-vars;
@use "./form";

.root {
  position: relative;
}

.currency {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: calc(12px + 1px);
  height: 100%;
  color: p-fin-vars.$label-color;
  font-size: 12px;
  font-weight: font.font-weight("regular");
  line-height: 14px;
}

.formControl {
  @include form.field-input;
  padding-left: 48px;
}
