@use "../../styles/color-palette";
@use "../../styles/font";

.label {
  font-size: 16px;
  font-weight: font.font-weight("regular");
  color: color-palette.get-color("text");
}

.select {
  font-size: 16px;
  font-weight: font.font-weight("semiBold");
  color: color-palette.get-color("secondary-1");
}
