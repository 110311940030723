@use "sass:color";
@use "../../styles/bootstrap/utils" as bs-utils;
@use "../../styles/color-palette";
@use "../../styles/font";
@use "../../styles/container";
@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.toolbarContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    margin-bottom: 0;
    color: white;
    font-weight: font.font-weight("regular");
  }

  .note {
    color: white;
    font-size: 12px;
    font-weight: font.font-weight("regular");
  }
}

.reportRenderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;

  &.rendering {
    width: 1150px;
    pointer-events: none;

    .reportRenderOverlay {
      display: block;
    }
  }
}
.reportRenderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: color-palette.get-color("background-1");
  z-index: 1000;
}

.mainContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  @include bs-utils.media-breakpoint-down(lg, bs-utils.$grid-breakpoints) {
    .reportRenderContainer:not(.rendering) & {
      flex-direction: column;
    }
  }
}

.chartCol {
  display: flex;
  flex-direction: column;
  gap: 64px;
  flex: 1 0 auto;
  width: 66.66%;
  @include container.vertical-padding;
  padding-right: 36px;

  @include bs-utils.media-breakpoint-down(lg, bs-utils.$grid-breakpoints) {
    .reportRenderContainer:not(.rendering) & {
      gap: 24px;
      flex: none;
      width: 100%;
      padding-bottom: 24px;
      padding-right: 0;
    }
  }
}

.detailsCol {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 auto;
  width: 33.33%;
  @include container.vertical-padding;
  padding-left: 36px;
  box-shadow: -2px 2px 5px 0
    color.scale(color-palette.get-color("shadow"), $alpha: -50%);

  @include bs-utils.media-breakpoint-down(lg, bs-utils.$grid-breakpoints) {
    .reportRenderContainer:not(.rendering) & {
      gap: 18px;
      flex: none;
      width: 100%;
      padding-top: 24px;
      padding-left: 0;
      box-shadow: none;
    }
  }
}

.detailsColHeader {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: p-fin-vars.$primary-color;
    font-size: 14px;
    font-weight: font.font-weight("regular");

    &::before {
      content: "";
      width: 2px;
      height: 24px;
      margin-right: 8px;
      background-color: p-fin-vars.$primary-color;
    }
  }

  .description {
    color: #95989d;
    font-size: 12px;
    font-weight: font.font-weight("medium");
  }
}

.barChartScrollContainer {
  overflow-x: auto;
}
.barChart {
  min-width: 360px;
}

.termsTableScrollContainer {
  overflow-x: auto;
}
.termsTable {
  min-width: 600px;
}

.resultDetailsTable {
  .labelCell {
    color: p-fin-vars.$label-color;
    font-size: 12px;
    font-weight: font.font-weight("medium");

    &.bold {
      font-weight: font.font-weight("semiBold");
    }

    .minus {
      margin-right: 5px;
      color: p-fin-vars.$negative-color;
      font-size: 10px;
    }

    .subLabel {
      color: #868686;
      font-weight: font.font-weight("regular");
    }
  }

  .valueCell {
    color: p-fin-vars.$label-color;
    font-size: 12px;
    font-weight: font.font-weight("regular");

    &.bold {
      font-weight: font.font-weight("medium");
    }
    &.negative {
      color: p-fin-vars.$negative-color;
    }
  }
}

.summaryTableWrapper {
  padding: 4px;
  border: 1px dashed #cce5fa;
  border-radius: 4px;

  .table {
    width: 100%;
  }

  .labelCell {
    padding: 4px;
    color: p-fin-vars.$label-color;
    font-size: 12px;

    &.bold {
      font-weight: font.font-weight("semiBold");
    }
  }

  .valueCell {
    padding: 4px;
    font-size: 12px;
    text-align: right;
  }
}
