@use "../../../styles/color-palette";
@use "../../../styles/font";
@use "../variables" as vars;
@use "../mixins";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: vars.$item-height;
  padding: 12px 24px;
  background-color: color-palette.get-color("background-1");
  color: color-palette.get-color("text");
  transition: color, min-height, padding, background-color 0.3s;

  &:hover {
    opacity: 0.7;
  }

  @include mixins.when-menu-link-is-active {
    color: white;
    background-color: color-palette.get-color("primary-2");
  }

  @include mixins.when-sidebar-collapsed {
    flex-direction: column;
    justify-content: center;
    min-height: vars.$item-collapsed-height;
    padding: 0 8px;
    background-color: inherit;
  }

  @include mixins.when-sidebar-collapsed-and-menu-link-is-active {
    background-color: color-palette.get-color("primary-2");
  }
}

.iconWrapper {
  svg {
    width: 44px;
    height: 44px;
    transition: width, height 0.3s;

    @include mixins.when-sidebar-collapsed {
      width: vars.$item-icon-collapsed-size;
      height: vars.$item-icon-collapsed-size;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-width: 0; // (Nicolas 08Jun2022) fix sizing issue inside flex-row
  transition: margin 0.3s;

  @include mixins.when-sidebar-collapsed {
    margin-left: 0;
    margin-top: 4px;
  }
}

.label {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: vars.$item-label-font-size;
  font-weight: font.font-weight("bold");
  color: #2848A9;

  @include mixins.when-menu-link-is-active {
    color: white;
  }

  @include mixins.when-sidebar-collapsed {
    display: none;
  }
}

.linkLabel {
  font-size: 14px;
  font-weight: font.font-weight("regular");
  color: color-palette.get-color("secondary-hover");
  text-decoration: underline;
  transition: font-size 0.3s;

  @include mixins.when-sidebar-collapsed {
    font-size: vars.$item-label-collapsed-font-size;
    color: color-palette.get-color("text");
    text-decoration: none;
  }

  @include mixins.when-menu-link-is-active {
    color: white;
  }
}
