@use "../../../styles/font";

.root {
  padding: 3px;
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 0;
  text-align: left;
}

.valueWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 0;
  text-align: right;
}
