@use "../../styles/font";
@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

.root {
  width: 100%;
}

.headerCell {
  background-color: p-fin-vars.$primary-background-color;
  padding: 8px 12px;
  text-align: center;
  color: p-fin-vars.$label-color;
  font-size: 14px;
  font-weight: font.font-weight("regular");

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .headerLabelWithLegendIndicator {
    display: flex;
    flex-direction: row;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 16px;
      margin-right: 8px;
    }
  
    &.principal::before {
      background-color: p-fin-vars.$chart-principal-color;
    }
    &.expense::before {
      background-color: p-fin-vars.$chart-expense-color;
    }
    &.return::before {
      background-color: p-fin-vars.$chart-return-color;
    }
  }

  .headerLabel {
    vertical-align: middle;
  }
}

.dataCell {
  padding: 5px 12px;
  text-align: center;
  color: #6d717b;
  font-size: 12px;
  font-weight: font.font-weight("regular");
}
