@use "sass:color";
@use "../color-palette";

$primary-color: color-palette.get-color("primary-2");
$primary-background-color: color.adjust($primary-color, $lightness: 30%, $saturation: -30%);

$label-color: #525864;

$positive-color: #68ba93;
$positive-background-color: #3bd88c;

$zero-color: #489ac7;
$zero-background-color: #489ac7;

$negative-color: #eb8c8c;
$negative-background-color: #eb8c8c;

$chart-principal-color: #f6cb7e;
$chart-loan-color: #eda16a;
$chart-expense-color: #eb8c8c;
$chart-return-color: #cae17d;
$chart-discount-color: #84d09d;

$chart-principal-color-with-opacity: rgba($chart-principal-color, 0.7);
$chart-loan-color-with-opacity: rgba($chart-loan-color, 0.7);
$chart-expense-color-with-opacity: rgba($chart-expense-color, 0.7);
$chart-return-color-with-opacity: rgba($chart-return-color, 0.7);
$chart-discount-color-with-opacity: rgba($chart-discount-color, 0.7);
