@use "sass:map";
@use "../color-palette";
@use "../font";

//
// Import Bootstrap styles
//

@import "./variables";
@import "~bootstrap/scss/bootstrap";

//
// Override Bootstrap styles
//

// --- Button ---

$button-text-colors: (
  primary: white,
  secondary: white,
  danger: white,
  light: color-palette.get-color("primary-1"),
  dark: white,
  bombay: white,
);

@each $color, $value in $theme-colors {
  $text-color: map.get($button-text-colors, $color);
  .btn-#{$color} {
    @include button-variant(
      $background: $value,
      $border: $value,
      $color: $text-color,
      $hover-color: $text-color,
      $disabled-color: $text-color
    );
  }
  .btn-outline-#{$color} {
    @include button-outline-variant(
      $value,
      $color-hover: $text-color,
      $active-background: mix($value, white, 70),
      $active-color: $text-color
    );
  }
}

.btn-sm {
  line-height: $btn-line-height-sm;
}
.btn-lg {
  line-height: $btn-line-height-lg;
  font-weight: $btn-font-weight-lg;
}

//
// Additional classes
//

.btn-rounded {
  padding: 12px 18px;
  border-radius: calc(38px / 2);
  font-size: 12px;
  line-height: 14px;
}
