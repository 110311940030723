@use "../styles/bootstrap/utils" as bs-utils;
@use "../styles/react-pro-sidebar";

.appLayout {
  height: 100%;
  display: flex;
}

.sideMenu {
  flex-grow: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}

.contentContainer {
  flex-grow: 1;
  margin-left: react-pro-sidebar.$sidebar-collapsed-width;
  overflow-x: hidden; // prevent horizontal scrolling
  transition: margin-left react-pro-sidebar.$sidebar-transition-duration;

  &.offset {
    margin-left: react-pro-sidebar.$sidebar-width;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
