.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftIconWrapper {
  margin-right: 8px;

  // Prevent 3px offset for inline elements
  > * {
    display: block;
  }
}

.rightIconWrapper {
  margin-left: 8px;

  // Prevent 3px offset for inline elements
  > * {
    display: block;
  }
}
