@use "../../styles/p-fin-calculator/variables" as p-fin-vars;

.positive {
  color: p-fin-vars.$positive-color;
}
.zero {
  color: p-fin-vars.$zero-color;
}
.negative {
  color: p-fin-vars.$negative-color;
}
