.root {
  &.disabled {
    pointer-events: none;
  }
}

.link {
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}