.modalDialog {
  max-height: 85vh;
}

.modalContent {
  max-height: 85vh;
  height: 100%;
}

.modalBody {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 0;
}

.pinchZoomImage {
  flex: 1;
  min-height: 0;
  overflow: visible !important;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.caption {
  width: 100%;
}
