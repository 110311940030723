@mixin when-sidebar-collapsed {
  :global(.pro-sidebar.collapsed) & {
    @content;
  }
}

@mixin when-menu-link-is-active {
  :global(.menu-link-item.active) & {
    @content;
  }
}

@mixin when-sidebar-collapsed-and-menu-link-is-active {
  :global(.pro-sidebar.collapsed .menu-link-item.active) & {
    @content;
  }
}
