.backgroundTopLeft {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  pointer-events: none;
}

.backgroundBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
  pointer-events: none;
}
